import { useCreateConnectSession } from '@texturehq/react-connect-sdk';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const ConnectComponent = ({ ph, referenceId, setShowConnectComponent, isShowConnectComponent, setLoading }) => {
  console.log('session.scopedKey');
  console.log(referenceId);
  console.log(ph);
  const connectApiKey = process.env.REACT_APP_CONNECT_API_KEY;
  const onboardApiKEY = process.env.REACT_APP_TEXTURE_DEVICE_ONBOARD_API_KEY;
  const onboardBaseUrl = process.env.REACT_APP_TEXTURE_DEVICE_ONBOARD_URL;
  const connectLink = process.env.REACT_APP_CONNECT_LINK;
  
  const navigate = useNavigate();
  // const { open, close } = useCreateConnectSession({
  //   connectApiKey: connectApiKey,
  //   connectOptions: {
  //     referenceId: referenceId,
  //     clientName: 'Daylight',
  //     redirectUrl: 'http://localhost:3000/',
  //     phone: ph,
  //     tags: ['Daylight onboard', ph]
  //   },
  //   onSuccess: (session) => {
  //     console.log(session.scopedKey);
  //     var myHeaders = new Headers();
  //     myHeaders.append("Authorization", `Bearer ${onboardApiKEY}`);
  //     myHeaders.append("Content-Type", "application/json");

  //     var raw = JSON.stringify({
  //       referenceId: referenceId,
  //       scopedKey: session.scopedKey
  //     });

  //     var requestOptions = {
  //       method: 'POST',
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: 'follow'
  //     };

  //     fetch(onboardBaseUrl, requestOptions)
  //       .then(response => response.text())
  //       .then(result => {
  //         setLoading(false)
  //         navigate("/complete",{replace:true});
  //         console.log(result); 
  //       })
  //       .catch(error => console.log('error', error));
  //       setShowConnectComponent(false);
      
  //   },
  //   onError: (error) => {
  //     console.log('error :', error);
  //     setShowConnectComponent(false);
  //   }
  // });

  // useEffect(() => {
  //   if (isShowConnectComponent) {
  //     open();
  //     // setLoading(false);
  //   }

  //   return () => {
  //     close();
  //     // setLoading(false);
  //   };
  // }, [close, isShowConnectComponent, open, setLoading]);

  useEffect(() => {
    setShowConnectComponent(false);
    // setLoading(false);
  }, [ph, referenceId, setLoading, setShowConnectComponent]);


  useEffect(() => {
    localStorage.setItem('phoneNumber', ph);
    localStorage.setItem('referenceId', referenceId);
    const apiUrl = 'https://api.texture.energy/v1/connections';
    const apiKey = connectApiKey;

    const payload = {
      referenceId: referenceId,
      clientName: "Daylight",
      redirectUrl: connectLink + '/complete',
      phone: ph,
      tags: ["Daylight onboard", ph]
    };

    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Texture-Api-Key': apiKey 
          },
          body: JSON.stringify(payload) 
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        window.location.href = data.linkUrl; 

        // const data = await response.json();
        // console.log('Texture API Response:', data); 
        // Do something with your linkToken and linkUrl here
      } catch (error) {
        console.error('Error fetching data:', error); 
      }
    };

    fetchData(); 
  }, []); 


  return null;
};

export default ConnectComponent;
