import React, {
  // useEffect,
  useState
} from 'react';
import PhoneInput from 'react-phone-input-2';
import './MainScreen.scss';
import Button from '../Button/Button';
import { CgSpinner } from 'react-icons/cg';
import homeImage1 from '../../assets/images/customer-portal-img1.png';
import daylightLog from '../../assets/images/customer-portal-logo.png';
import ConnectComponent from './ConnectComponent';

const WEBSITE_URL = process.env.REACT_APP_WEBSITE_LINK;

const RegistrationUsingSDK = () => {
  const [ph, setPh] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConnectComponent, setShowConnectComponent] = useState(false);

  const handlePhoneNumberChange = (value) => {
    const inputValue = value;
    const formattedPhoneNumber = inputValue.startsWith('1') ? inputValue : `1${inputValue}`;
    setPh(formattedPhoneNumber);
    const timestamp = Date.now();
    const referenceId = `onboard_${timestamp}_${formattedPhoneNumber}`;
    setReferenceId(referenceId);
    // console.log(referenceId);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Backspace' && ph === '1') {
    }
  };

  const handleSdkCall = async () => {
    localStorage.setItem('phoneNumber', ph);
    setLoading(true);
    setShowConnectComponent(true);
    // setIsTermsAndConditionsChecked(false);
  };

  // TODO: resetting the state on phoneNumber change
  // useEffect(() => {
  //   setShowConnectComponent(false);
  //   setLoading(false);
  // }, [ph]);

  return (
    <>
      <section className="customer-portal">
        <div className="flex justify-center items-start sm:flex-row flex-col sm:h-screen h-full">
          <div
            className={`!bg-primary sm:px-0 sm:py-[40px] p-[45px] text-center sm:w-1/2 w-full sm:h-full h-[390px] flex justify-around items-center flex-col`}>
            <div>
              <img
                className="sm:max-w-[181px] max-w-[105px] lg:max-h-[52px] max-h-[30px] my-[12px] mx-auto"
                src={daylightLog}
                alt=""
              />
              <h2 className="text-[#F0EEFC] lg:text-[57px] sm:text-[40px] text-[28px] sm:leading-[64px] leading-[36px] text-center lg:mt-[75px] mt-[20px] max-w-[350px] mx-auto">
                Connect
              </h2>
            </div>

            <div>
              <img className="max-w[790px] w-[70%] mx-auto" src={homeImage1} alt="" />
            </div>
          </div>
          <div className="sm:w-1/2 sm:m-auto mt-[46px]  mx-[48px]">
            <div className="sm:w-[80%] w-full mb-[20px] mx-auto">
              <div>
                <h3 className="text-primary sm:text-[32px] text-[28px] sm:leading-[40px] leading-[36px] mb-[10px]">
                  Welcome to Daylight!
                </h3>
                <p className="text-primary sm:text-[16px] text-[14px] sm:leading-[24px] leading-[20px] mb-[40px]">
                  Please enter your phone number to continue.
                </p>
                <div className="mb-[40px]">
                  <PhoneInput
                    country={'us'}
                    onlyCountries={['us']}
                    preserveOrder={['onlyCountries', 'preferredCountries']}
                    value={ph}
                    onChange={handlePhoneNumberChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter phone number"
                    disableDropdown
                    autoFormat
                    className="peer my-0 [&>.flag-dropdown]:!hidden [&>input]:!pl-0 [&>input]:!bg-transparent [&>input]:py-0 h-full w-full border-b-2 sm:[&>input]:!w-full [&>input]:!w-[80%] [&>input]:!text-lg [&>input]:lg:text-xl [&>input]:!h-[50px] [&>input]:!text-primary  border-tertiary bg-transparent text-[22px] leading-[28px] font-normal text-primary outline outline-0 transition-all [&>input]:focus:!ring-0 [&>input]:!outline-none [&>input]:placeholder-shown:!border-quaternary [&>input]:placeholder:!text-quaternary focus:border-tertiary focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 [&>input]:placeholder:!text-lg [&>input]:lg:placeholder:!text-xl [&>input]:[appearance:textField] [&>input]:[&::-webkit-outer-spin-button]:appearance-none [&>input]:[&::-webkit-inner-spin-button]:appearance-none [&>input]:!border-none [&>input]:!rounded-none [&>div]:!border-none [&>div]:!rounded-none [&>div]:!bg-quaternary"
                  />
                </div>

                <div className="flex items-center mr-4 mb-[40px]">
                  <input
                    checked={isTermsAndConditionsChecked}
                    id="terms-checkbox"
                    type="checkbox"
                    value="terms"
                    onChange={(e) => {
                      console.log('e :', e.currentTarget.checked);
                      setIsTermsAndConditionsChecked(e.currentTarget.checked);
                    }}
                    className="sm:!w-[30px] !w-[25px] sm:!h-[30px] !h-[25px] sm:scale-100 scale-150 !text-primary bg-gray-100 border-gray-300 rounded !focus:ring-red-500 dark:!focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />

                  <label
                    htmlFor="terms-checkbox"
                    className="ml-2 sm:text-[12px] text-[11px] leading-[16px] tracking-[0.1px] text-primary checkbox-container">
                    By clicking on connect, you agree to receive communication from Daylight Energy
                    for reminders, promotions, alerts, or company updates. Message frequency varies.
                    Messages and data rates may apply. Reply STOP to cancel. View our{' '}
                    <a
                      href={WEBSITE_URL + 'legal/terms/'}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cursor-pointer">
                      <u className="font-aeonik-bold"> Terms & Conditions</u>
                    </a>{' '}
                    and{' '}
                    <a
                      href={WEBSITE_URL + 'legal/privacy/'}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cursor-pointer">
                      <u className="font-aeonik-bold"> Privacy Policy</u>
                    </a>
                  </label>
                </div>

                <Button
                  onClick={handleSdkCall}
                  disabled={
                    showConnectComponent ||
                    loading ||
                    !(ph.length === 11 && isTermsAndConditionsChecked)
                  }
                  size="large"
                  className="!py-[12px] sm:!min-w-[207px] min-w-full h-[50px] font-aeonik-bold !rounded-full !bg-[#F1D972] hover:!bg-[#D3B01A] disabled:!bg-gray-200 disabled:cursor-none"
                  theme="primary">
                  <span className="flex items-center !text-[18px] !text-primary !leading-[16px]">
                    {loading && <CgSpinner size={20} className="animate-spin mr-3 items-center" />}{' '}
                    Connect
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showConnectComponent && (
        <ConnectComponent
          ph={ph}
          referenceId={referenceId}
          setShowConnectComponent={setShowConnectComponent}
          isShowConnectComponent={showConnectComponent}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default RegistrationUsingSDK;
