import { Routes, Route } from 'react-router-dom';
import SignupPage from './pages/SignupPage';
import { Toaster } from 'react-hot-toast';
import PageNotFound from './pages/PageNotFound';
import ThanksScreen from './components/MainScreen/ThanksScreen';
import AddDeviceScreen from './components/MainScreen/AddDeviceScreen';


function App() {
  return (
    <>
      <Toaster toastOptions={{ duration: 6000 }} />
      <Routes>
        <Route path="/" element={<SignupPage />} />
        <Route path="/complete" element={<ThanksScreen />} />
        <Route path="/add-device/:phoneNumber" element={<AddDeviceScreen />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
