import React, { useEffect } from 'react';
import './MainScreen.scss';
import homeImage1 from '../../assets/images/customer-portal-img1.png';
import daylightLog from '../../assets/images/customer-portal-logo.png';
import appStoreLogo from '../../assets/images/app_store_badge.png';
import { useLocation } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';

const ThanksScreen = () => {
  // const connectLink = process.env.REACT_APP_CONNECT_LINK;
  const downloadApp =  process.env.REACT_APP_LINK;
  const onboardApiKEY = process.env.REACT_APP_TEXTURE_DEVICE_ONBOARD_API_KEY;
  const onboardBaseUrl = process.env.REACT_APP_TEXTURE_DEVICE_ONBOARD_URL;
  const phoneNumber = localStorage.getItem('phoneNumber');
  const referenceId = localStorage.getItem('referenceId');
  // or use sessionStorage
  const navigate = useNavigate();
  const location = useLocation();

  const handleAddNewDevice = () => {
    navigate(`/add-device/${phoneNumber}`);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scopedKey = params.get('texture_scoped_key'); 
    if (scopedKey) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${onboardApiKEY}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        referenceId: referenceId,
        scopedKey: scopedKey
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(onboardBaseUrl, requestOptions)
        .then(response => response.text())
        .then(result => {
          navigate("/complete",{replace:true});
          console.log(result); 
        })
        .catch(error => console.log('error', error));
      

      console.log('Scoped Key:', scopedKey);
    } else {
      console.error('No scoped key returned');
    }
}, []);

  return (
    <>
      <section className="customer-portal">
        <div className="flex justify-center items-start sm:flex-row flex-col sm:h-screen h-full">
          <div
            className={`!bg-primary sm:px-0 sm:py-[40px] p-[45px] text-center sm:w-1/2 w-full sm:h-full h-[390px] flex justify-around items-center flex-col`}>
            <div>
              <img
                className="sm:max-w-[181px] max-w-[105px] lg:max-h-[52px] max-h-[30px] my-[12px] mx-auto"
                src={daylightLog}
                alt=""
              />
              <h2 className="text-[#F0EEFC] lg:text-[57px] sm:text-[40px] text-[28px] sm:leading-[64px] leading-[36px] text-center lg:mt-[75px] mt-[20px] max-w-[350px] mx-auto">
                Connect
              </h2>
            </div>

            <div>
              <img className="max-w[790px] w-[70%] mx-auto" src={homeImage1} alt="" />
            </div>
          </div>
          <div className="sm:w-1/2 sm:m-auto mt-[46px]  mx-[48px]">
            <div className="sm:w-[80%] w-full mb-[20px] mx-auto">
              <div>
                <h3 className="text-primary sm:text-[40px] font-sold text-center mb-3">
                  Thank You!
                </h3>
                <p className='text-primary text-center sm:text-[16px]'>Your device is onboarded, Please <a className='hover:underline font-semibold' href={downloadApp}>Download </a> Daylight app to manage your devices .</p>
                <p className='text-primary text-center sm:text-[16px]'>
                <button
                  className="mt-4 py-2 px-4 bg-primary text-white font-semibold rounded-lg shadow-md hover:bg-primary-dark"
                  onClick={handleAddNewDevice}
                >
                  Onboard another device
                </button>
                </p>
                <p className='text-primary text-center sm:text-[16px] mt-[46px] '><a className='hover:underline font-semibold' href={downloadApp}>
                  <img
                    className="mx-auto max-w-[40%]"
                    src={appStoreLogo}
                    alt=""
                  />
                  </a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThanksScreen;