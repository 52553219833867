import React from 'react';
import './SignupPage.css';
import RegistrationUsingSDK from '../components/MainScreen/RegistrationUsingSDK';

const SignupPage = () => {
  return (
    <div>
      <RegistrationUsingSDK />
    </div>
  );
};

export default SignupPage;
